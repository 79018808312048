/* responsive  */
@media (max-width: 480px) {
  .abcwd {
    display: none;
  }
  .kjs {
    justify-content: center;
  }
  .djas {
    padding: 10px;
  }
  .hadw {
    padding: 7px;
  }
  .position-abs-dawj {
    width: 100%;
  }
  .search-destination-ajd {
    background: var(--white);
    width: 80%;
  }
  .btn-submit {
    width: 100%;
    margin: none;
  }
  .Toastify__toast-container {
    padding: 5px !important;
  }
  .filter-jdawjd {
    margin-right: 0;
  }
  .responsive-mobile-form-layanan-kd {
    padding: 10px;
  }
  .navMenu {
    width: 100%;
  }

  .card-syarat-ketentuan-jjs {
    padding: 10px;
    border: 2px solid #f5f5f5;
    border-radius: 20px;
    text-align: justify;
    font-size: 13px;
    position: relative;
    overflow: auto;
    transition: all 0.3s ease-out;
    max-height: 432px;
  }
  .nav-top-login {
    width: 100%;
  }
  /* .show-ketentuan-jsd {
    max-height: 432px;
    transition: all 0.3s ease-in;
  } */
  /* .hide-ketentuan-ssdbb {
    display: none;
  } */
  .btn-position-sjs {
    text-align: right;
    display: none;
  }
  .title-layanan-badan-usaha-dwd {
    overflow: auto;
  }
  .jdawdi {
    width: 100%;
  }
  .jdawdi-dawda {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    margin: auto;
  }
}

@media (min-width: 480px) and (max-width: 575.98px) {
  .navbar {
    width: 480px;
    margin: auto;
  }
  .responsive-class {
    width: 480px;
    margin: auto;
  }
  .container-desktop-footer {
    width: 480px;
    margin: auto;
  }
  .navMenu {
    width: 480px;
    margin: auto;
  }
  .kjs {
    justify-content: center;
  }
  .position-abs-dawj {
    position: fixed;
    width: 480px;
    margin: auto;
  }
  .abcwd {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .navbar {
    width: 480px;
    margin: auto;
  }
  .responsive-class {
    width: 480px;
    margin: auto;
  }
  .container-desktop-footer {
    width: 480px;
    margin: auto;
  }

  .position-abs-dawj {
    position: fixed;
    width: 480px;
    margin: auto;
  }
  .abcwd {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar {
    width: 480px;
    margin: auto;
  }
  .responsive-class {
    width: 480px;
    margin: auto;
  }
  .container-desktop-footer {
    width: 480px;
    margin: auto;
  }

  .position-abs-dawj {
    position: fixed;
    width: 480px;
    margin: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar {
    width: 480px;
    margin: auto;
  }
  .container-desktop {
    width: 480px;
    margin: auto;
  }
  .container-desktop-footer {
    width: 480px;
    margin: auto;
  }
  .responsive-class {
    width: 480px;
    margin: auto;
  }

  .position-abs-dawj {
    position: fixed;
    width: 480px;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .navbar {
    width: 480px;
    margin: auto;
  }
  .container-desktop {
    width: 480px;
    margin: auto;
  }
  .container-desktop-footer {
    width: 480px;
    margin: auto;
  }
  .responsive-class {
    width: 480px;
    margin: auto;
  }
  .hadw {
    margin-right: 16px;
  }
  .position-abs-dawj {
    position: fixed;
    width: 480px;
    margin: auto;
  }
  .title-layanan-badan-usaha-dwd:hover {
    overflow: auto;
  }
}
