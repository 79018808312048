.card-ndwajdn {
  border: 1px solid #939393;
  border-radius: 14px 20px 0px 20px;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 20%);
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.abcwd {
  width: 100%;
}
.card-ndwajdn:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
}
.keterangan-notif {
  text-align: center;
}
.title-keterangan-notif {
  font-size: 16px;
  font-weight: 600;
}
.p-keterangan-notif {
  font-size: 13px;
  margin-bottom: 10px;
}
.img-success-kdd {
  margin-top: 60px;
}
.img-success-kdd img {
  height: 300px;
}
