.profile.d-flex {
  margin-top: 90px;
  padding: 15px;
}

.icon-profile.mr-2 {
  background: var(--gold-black);
  padding: 5px;
  font-size: 30px;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 10px;
}
.nm {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 0;
}
.wd {
  font-size: 10px;
  font-weight: 100;
}
.rw {
  background: var(--abu-abu);
  color: white;
  padding: 2px 10px;
  border-radius: 15px;
  font-size: 10px;
  cursor: pointer;
}

.dashboard-content-s {
  padding: 95px 15px;
}
.djas {
  border-radius: 20px;
  margin-top: 5px;
  background: #c09c31;
  padding: 15px 15px;
}
.card-white-s {
  padding: 10px;
  border-radius: 20px;
  position: relative;
  padding: 15px;
  background: var(--white);
}
.da {
  font-size: 40px;
  font-weight: 600;
  color: var(--black);
  text-decoration: none;
  line-height: 1;
}
.da:hover {
  color: var(--gold-black);
  transform: scale(1.1);
  transition: all 0.3s ease-out;
}
.hsj {
  text-decoration: none;
  color: var(--black);
  margin: 0;
}
.ops {
  max-width: 90px;
}
.kjs {
  display: flex;
  align-items: center;
  padding: 5px;
}
.hadw {
  text-align: center;
  color: white;
  font-size: 9px;
  padding: 10px;
  text-decoration: none;
}
.hadw:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.1);
  color: var(--black);
}
.dap {
  background: white;
  padding: 5px;
  font-size: 30px;
  border-radius: 5px;
  color: #c09c31;
}
.slider-center-sadn {
  text-align: center;
}
.card-spwdnaj {
  margin-right: 10px;
  cursor: pointer;
}
.jadab {
  max-width: 100%;
  margin: auto;
}
.adwg {
  background: var(--gold-black);
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}
.dajd {
  font-size: 13px;
  margin: 0;
  color: white;
  text-align: left;
}
.dadkksa {
  font-size: 10px;
  color: white;
  font-weight: 100;
  text-align: left;
  margin: 0;
  padding: 0px 0px 6px 0px;
  border-bottom: 1px solid #ffffff94;
}
.bdahd {
  position: relative;
  font-size: 10px;
  color: white;
  text-align: left;
  z-index: 2;
}
.icon-ndajdb {
  position: absolute;
  bottom: -4px;
  right: -10px;
  z-index: 1;
  opacity: 0.6;
}
.vawfwfa {
  max-width: 60px;
}
.slick-slide.slick-active.slick-current {
  transform: scale(1) !important;
}
.slick-slide.slick-active:first-child {
  transform: scale(1.1) !important;
}
/* .slick-slide.slick-active {
  transform: scale(1);
} */
.orange {
  color: var(--gold-black);
}
.slider-dadnknk {
  margin-bottom: 70px;
}
.dadw {
  font-size: 11px;
  margin: 0;
  font-weight: 600;
}
.bdahb {
  color: var(--gold-black);
  font-weight: 600;
  margin: 0;
}
.nadk {
  font-size: 13px;
}
.andj {
  font-size: 10px;
}

.andj {
  font-size: 12px;
  margin: 0;
}
.gead {
  font-weight: 700;
}
.daann {
  background: white;
  padding: 5px;
  border-radius: 5px;
}
.ndajb {
  font-size: 12px;
}
.adbw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dnajd {
  font-size: 11px;
}
.dadafgg {
  font-size: 10px;
  text-decoration: none;
  color: blue;
}
.card-ndanma {
  border: 1px solid #ff9416;
}
.content-dnkdw {
  height: 0;
}
.button-keuntungan-da {
  position: relative;
  text-align: center;
  padding: 6px 2px;
  box-shadow: 1px 1px 10px 0px rgb(0 0 0 / 10%);
  overflow: auto;
  border-radius: 15px;
}
.dkada {
  position: relative;
  text-decoration: none;
  padding: 2px 5px;
  margin-right: 0px;
  border: 1px solid #ff9416;
  font-size: 13px;
  background: var(--white);
  color: var(--gold-black);

  z-index: 2;
}
.dkada:hover {
  transition: all 0.3s ease-out;
  background: var(--gold-black);
  color: var(--white);
}
.dkada.active {
  transition: all 0.3s ease-out;
  background: var(--gold-black);
  color: var(--white);
}
.content-click-dadnajdw {
  margin-top: 20px;
  border-left: 25px solid var(--gold-black);
  padding: 10px;
  border-radius: 15px;
  box-shadow: 1px 1px 10px 0px rgb(0 0 0 / 10%);
}
.content-click-dadnajdw:hover {
  transition: all 0.3s ease-out;
  transform: scale(1.02);
}
.title-dna {
  font-size: 16px;
}
.p-ndajdb {
  font-size: 14px;
}
.dkada.active:before {
  content: " ";
  padding: 1px;
  background: var(--gold-black);
  width: 10px;
  height: 18px;
  position: absolute;
  transform: rotate(45deg);
  left: 39px;
  bottom: -5px;
  z-index: -1;
}
.card-kkmcn-0 {
  padding: 10px;
  border-radius: 10px;

  animation: border-bottom 0.3s ease-in;
  background: #35b808;
  cursor: pointer;
}
.ccad {
  max-width: 140px;
}
.card-kkmcn-0:hover {
  opacity: 0.5;
  transition: all 0.3s ease;
}
.card-kkmcn-1 {
  padding: 10px;
  border-radius: 10px;

  animation: border-bottom 0.3s ease-in;
  background: #258503;
  cursor: pointer;
}
.card-kkmcn-1:hover {
  opacity: 0.5;
  transition: all 0.3s ease;
}
.card-kkmcn-2 {
  padding: 10px;
  border-radius: 10px;

  animation: border-bottom 0.3s ease-in;
  background: #ee4d30;
  cursor: pointer;
}
.card-kkmcn-2:hover {
  opacity: 0.5;
  transition: all 0.3s ease;
}
.card-kkmcn-3 {
  padding: 10px;
  border-radius: 10px;

  animation: border-bottom 0.3s ease-in;
  background: #05149a;
  cursor: pointer;
}
.card-kkmcn-3:hover {
  opacity: 0.5;
  transition: all 0.3s ease;
}
.card-kkmcn-4 {
  padding: 10px;
  border-radius: 10px;

  animation: border-bottom 0.3s ease-in;
  background: #2736c2;
  cursor: pointer;
}
.card-kkmcn-4:hover {
  opacity: 0.5;
  transition: all 0.3s ease;
}
.card-kkmcn-5 {
  padding: 10px;
  border-radius: 10px;

  animation: border-bottom 0.3s ease-in;
  background: #d92505;
  cursor: pointer;
}
.card-kkmcn-5:hover {
  opacity: 0.5;
  transition: all 0.3s ease;
}
.content-body-dawd {
  font-size: 13px;
  text-align: center;
}
.title-layanan {
  font-size: 13px;
  text-align: center;
}
.img-layanan-jjdk {
  width: 100%;
}
.jjdw {
  text-align: justify;
  font-size: 13px;
  margin-bottom: 0;
}
.img-xy {
  width: 100%;
}
