.outside-container {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background: #cdcdcd;
}
.container-nav-top-f {
  padding: 18px 15px;
  border-bottom: 1px solid #e0e0e0;
  background: var(--bg-color1);
  width: 480px;
  margin: auto;
}

.nav-button-f {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nav-button-f svg {
  margin-right: 17px;
  transition: all 0.3s ease;
}
.nav-button-f svg:hover {
  opacity: 0.6;
  transform: scale(1.2);
}
.nav-button-f div {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: var(--font-color1);
}
