.content-slider {
  padding: 30px;
}
.title-welcome-page {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.p-welcome-page {
  color: #545454;
  font-size: 14px;
  text-align: justify;
}
