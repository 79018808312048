.navMenuFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.3s ease-in;
}

.navMenu {
  background: var(--white);
  border-radius: 30px 30px 0px 0px;
  border-top: 1px solid var(--abu-abu);
  padding: 10px 15px 5px 15px;
  width: 480px;
  margin: auto;
  position: relative;
}
.navMenuBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  width: 100%;
}
.zx {
  font-size: 15px;
  color: var(--black);
}
.btnNavMenu {
  display: block;
  text-align: center;
  text-decoration: none;
  color: var(--black);
  padding: 4px 0px;
  position: relative;
  width: 50px;
}

.active {
  color: var(--gold-black);
}
.btnNavMenu:hover {
  color: rgb(136, 135, 135);
}

.btnNavMenu:focus {
  color: var(--gold-black);
}

.btnNavMenu:active {
  color: var(--gold-black);
}

.btnNavMenu span {
  font-size: 11px;
}
.img-menu-footer-s {
  max-width: 22px;
}
.minimaze-menu-footer-ks {
  background: #fff;
  border: 1px solid #b5b5b5;
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 9px;
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translate(-50%, -0px);
  cursor: pointer;
}
.footer-hide {
  bottom: -55px;
  transition: all 0.3s ease-out;
}
.kdawkdaw {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #d92505;
  color: #fff;
  padding: 0px 4px;
  border-radius: 12px;
  font-size: 10px;
}
