.categori-menu-absolute {
  position: fixed;
  top: 80px;
  right: 230px;
  background: var(--white);
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
}
.categori-menu-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.categori-menu-li {
  border-bottom: 1px solid var(--abu-abu);
}
.btn-menu-categori-absolute {
  display: flex;
  align-items: center;
  min-width: 170px;
  padding: 10px 7px;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s all ease-in-out;
}
.btn-menu-categori-absolute svg {
  margin-right: 10px;
}
.btn-menu-categori-absolute:hover {
  color: var(--orange);
}
.menu-relative {
  position: relative;
  z-index: 2;
}
.menu-relative::before {
  content: " ";
  position: absolute;
  top: 1px;
  left: -3px;
  width: 20px;
  height: 40px;
  padding: 1px;
  z-index: -30;
  box-shadow: 0 1px 0px rgb(0 0 0 / 5%);
  background: var(--white);
  transform: rotate(45deg);
}
