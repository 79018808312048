.container-ndnjwdnawjd {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  margin: auto;
  transition: all 0.3s ease-in-out;
  z-index: 10001;
  background-color: rgba(0, 0, 0, 0.1);
}
/* .container-content-dwad {
  display: block;
  align-items: center;
  max-width: 480px;
  width: 100%;
  height: 100%;
  margin: auto;
 
  display: flex;
  align-items: center;
  justify-content: center;
} */
.loading {
  font-size: 20px;
  color: #010101;
}
.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #010101;
  border-color: #010101 transparent #010101 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
