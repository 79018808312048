.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  margin: auto;
  transition: all 0.3s ease-in-out;
  z-index: 10000;
}

.modal-content {
  background: white !important;
  margin: 15px !important;
  padding: 20px !important;
  border-radius: 6px !important;
}
.modal-content.top {
  background: white !important;
  margin: 15px !important;
  margin-top: -200px !important;
  padding: 20px !important;
  border-radius: 6px !important;
}
.modal-content h2 {
  font-size: 16px;
  font-weight: 400;
  color: var(--font-color4);
  line-height: 18px;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content button {
  margin-top: 10px;
}
.btn-modal-list {
  display: flex;
  align-items: center;
  justify-content: right;
}
.btn-modal-list .btn-modal-cancel {
  background: transparent;
  border: transparent;
  margin-right: 10px;
  color: #7b7b7b;
  padding: 8px 15px;
}
.btn-modal-list .btn-modal-cancel:hover {
  opacity: 0.6;
}
.btn-modal-list .btn-modal-accept {
  background: #efefef;
  border: transparent;
  margin-right: 10px;
  color: #7b7b7b;
  padding: 8px 15px;
}
.btn-modal-list .btn-modal-accept:hover {
  opacity: 0.6;
}
.body-modal-fjwdj {
  height: 300px;
  overflow: auto;
}
