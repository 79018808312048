.profil-pengacara-dadka {
  display: flex;
}
.profil-mitra-dwdb {
  margin-top: 20px;
}
.dwjnwjnd {
  font-size: 15px;
  font-weight: 600;
}
