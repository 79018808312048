.card-form-transaction-layanan {
  border: 1px solid #d3d3d3;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px 1px #cfcbcb;
}
.durasi-kkdmm {
  text-align: center;
  margin-top: 20px;
}
.minus-ssdwa {
  cursor: pointer;
  margin-right: 10px;
}
.plus-ssdwa {
  cursor: pointer;
  margin-left: 10px;
}
.durasi {
  background: var(--gold-black);
  padding: 3px 9px;
  color: #fff;
  border-radius: 15px;
}
.form-layanan {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}
.title-layanan-kkd {
  font-size: 16px;
}
.box-layanan-sjdj {
  border: 1px solid #f5f5f5;
  padding: 10px;
  margin-top: 10px;
  transition: 0.2s;
}
.box-layanan-sjdj:hover {
  transform: scale(1.02);
}
.title-jdah {
  font-size: 13px;
}
.box-layanan-sjdj ul {
  list-style: auto;
}
.box-layanan-sjdj ul li {
  font-size: 12px;
}

/* badan hukum  */

.layanan-badan-usaha-dwd {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 170px;
  position: relative;
  cursor: pointer;
}
.layanan-badan-usaha-dwd:hover {
  opacity: 0.8;
}

.layanan-badan-usaha-dwd {
  padding: 10px;
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 26%);
}
.title-layanan-badan-usaha-dwd {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #00000073;
  width: 230px;
  padding: 6px 6px;
  color: #fff;
  font-size: 30px;
  height: 170px;
  transition: 0.3s ease;
  z-index: 1;
  overflow: hidden;
}

.title-layanan-badan-usaha-dwd b {
  font-size: 15px;
}
.layanan-badan-usaha-dwd:hover .title-layanan-badan-usaha-dwd {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  transition: 0.3s ease;
}
.title-dawdaw {
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  color: #e1e1e1;
  float: right;
}
.layanan-paket-dwad ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.layanan-paket-dwad ul li {
  font-size: 11px;
}
.datetime-style-dawd {
  width: 100%;
}
.react-datetime-picker__wrapper {
  border: none;
  padding: 2px 10px;
  background: #f5f5f5;
}
